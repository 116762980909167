import theme from './../../../gatsby-plugin-theme-ui';

// PROPS

export const promo_display_container_props = side => ({
    sx: {
        position: 'relative',
        flexFlow: ['column nowrap', `${side === 'left' ? 'row nowrap' : 'row-reverse nowrap'}`],
        width: '100%',
        minHeight: 300,
        margin: '0.5rem 0 5rem 0'
    }
})

export const promo_display_dotted_line_props = (side, circle_quantity, ) => ({
    position: {
        left: side === 'left' ? ['1.25rem', '1.25rem', 0] : null,
        right: side === 'left' ? null : ['1.25rem', '1.25rem', 0],
    },
    quantity: [...circle_quantity]
})

export const promo_display_image_props = (side, pic) => ({
    sx: {
        width: ['calc(100% - 6.5rem)', 280],
        height: 216,
        maxWidth: 280,
        background: pic ? `url(${pic}) no-repeat center center / auto 90%` : theme.colors.grey,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        margin: side === 'left' ? ['0 auto', '0 0 0 3.75rem', '0 0 0 2.5rem'] : ['0 auto', '0 3.75rem 0 0' ,'0 2.5rem 0 0']
    }
})

export const promo_display_info_container_props = side => ({
    sx: {
        position: 'relative',
        width: ['100%', '60%', 'calc(80% - (280px - 7.5rem))'],
        padding: ['0 1.25rem',
        `0 ${side === 'left' ? '1.25rem' : '2.5rem'} 0 ${side === 'left' ? '2.5rem' : '1.25rem'}`,
        `0 ${side === 'left' ? '3.5rem' : '7.5rem'} 0 ${side === 'left' ? '7.5rem' : '3.5rem'}`],
        marginTop: ['3rem', 0]
    }
})

const border_props = (side, width, height, transform, display) => ({
    position: side === 'left' ? { top: 0, right: 0 } : { top: 0, left: 0 }, width, height, transform, display
})

export const border_top_props = side => border_props(
    side, '55%', 10, { transform: side === 'left' ? 'rotate(180deg)' : null }, null
)

export const border_side_props = side => border_props(
    side, 10, '65%', { transform: 'rotate(180deg)' }, { display: ['none', 'none', 'inherit'] }
)

export const promo_display_title_props = side => ({
    as: 'h2',
    fontSize: [20, 20, 22],
    fontWeight: 700,
    margin: '2.5rem 0',
    float: side === 'left' ? 'right' : null,
    textAlign: side === 'left' ? 'right' : 'left',
    padding: [0, 0, `${side === 'left' ? '0 0 0 3.5rem' : '0'}`]
})

export const promo_display_text_props = (old_price) => ({
    sx: {
        as: 'p',
        fontSize: [22, 24],
        fontFamily: theme.fonts.body,
        fontWeight: 700,
        clear: 'both',
        '> span': {
            position: 'relative',
            fontSize: 28,
            margin: '0 0 0 0.5rem',
            color: theme.colors.red,
            ':before': {
                content: `"${old_price}"`,
                minWidth: 200,
                position: 'absolute',
                top: ['2.5rem', '2rem'],
                left: '-4.75rem',
                fontWeight: 700,
                textAlign: 'center',
                color: theme.colors.grey,
                fontSize: ['calc(16px + 2.5rem)', 'calc(28px + 2.5rem)'],
                zIndex: -1,
            },
            ':after': {
                content: '""',
                width: 200,
                height: 2,
                background: theme.colors.red,
                position: 'absolute',
                top: '4.5rem',
                left: '-5rem',
                zIndex: -1
            }
        }
    }
})