import React from 'react';
import { Flex, Box, Text } from 'rebass/styled-components';

// STYLES
import {
  promo_display_dotted_line_props,
  promo_display_container_props,
  promo_display_image_props,
  promo_display_info_container_props,
  border_top_props,
  border_side_props,
  promo_display_title_props,
  promo_display_text_props
} from './styles'

// COMPONENTS
import Heading from './../../atoms/heading';
import DottedLine from './../../atoms/dottedLine';
import Border from './../../atoms/border';

export default function PromoDisplayItem(props) {

  const { side, title, old_price, new_price, pic } = props;

  const circle_quantity = num => new Array(num);

  return (
    <Flex {...promo_display_container_props(side)}
    data-sal={side === 'left' ? 'slide-right' : 'slide-left'}
    data-sal-duration="600"
    data-sal-delay="600"
    data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)">
      <DottedLine {...promo_display_dotted_line_props(side, circle_quantity(11))} />
      <DottedLine {...promo_display_dotted_line_props(side === 'left' ? 'right' : 'left', circle_quantity(11))} display={['inherit', 'none', 'none']} />
      <Box {...promo_display_image_props(side, pic)}
      data-sal='fade'
      data-sal-duration="600"
      data-sal-delay="1000"
      data-sal-easing="cubic-bezier(0.165, 0.84, 0.44, 1)"></Box>
      <Box {...promo_display_info_container_props(side)}>
        <Border {...border_top_props(side)} />
        <Border {...border_side_props(side)} />
        <Heading {...promo_display_title_props(side)}>{ title }</Heading>
        <Text {...promo_display_text_props(old_price)}>Cena brutto:<span>{` ${new_price} zł`}</span></Text>
      </Box>
    </Flex>
  );
}
