import React from 'react';
import shortid from 'shortid';

// DATA
import { promo_list } from './promocje';

// STYLES
import {
    promo_display_section_props,
    promo_display_heading_props
} from './styles'

// COMPONENTS
import Section from './../../atoms/section';
import Heading from './../../atoms/heading';
import PromoDisplayItem from './../../molecules/promoDisplayItem';

export default function PromoDisplaySection() {

  const promoList = promo_list.map((item, i)=>
    <PromoDisplayItem key={shortid.generate()} side={`${i % 2 === 0 ? 'left' : 'right'}`}
    pic={item.pic} title={item.title} old_price={item.old_price} new_price={item.new_price} />
  )

  return (
    <Section {...promo_display_section_props}>
        <Heading {...promo_display_heading_props}>Nasze promocje</Heading>
        { promoList }
    </Section>
  );
}
