// PROPS

export const promo_display_section_props = {
    sx: {
        width: '100%',
        height: 'auto',
        flexFlow: 'column nowrap'
    }
}

export const promo_display_heading_props = {
    as: 'h1',
    background_text: 'Promocje',
    margin: '2rem auto 3.5rem auto'
}