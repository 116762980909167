import React from 'react'

// COMPONENTS
import SEO from './../components/templates/seo';
import PromoDisplaySection from './../components/organisms/promoDisplaySection';

export default function Promo(props) {
    return (
        <>
            <SEO title='Promocje' />
            <PromoDisplaySection />
        </>
    )
}